<script
  setup
  lang="ts"
  generic="TEntity extends Exclude<keyof GA4Entities, 'cartList' & 'gaItem'>"
>
import { inject } from "vue"
import type { GA4Entities } from "nuxt-ga4"

const id = inject("id")
const statefulEntityList: GA4Entities[TEntity] | undefined =
  inject("statefulEntityList")
const statefulEntityItem: GA4Entities["gaItem"] | undefined =
  inject("statefulEntityItem")

const eventName = "select_item"
const eventKey = `event_${eventName}_${id}`
const event = "custom_event"

const gaEventRef = ref<any>()

const payload = computed(() => {
  return {
    event,
    action: eventName,
    ecommerce: {
      items: statefulEntityItem?.value ?? {}
    },
    item_list_name: statefulEntityList?.value?.list_name ?? ""
  }
})

const sendGA4EventSelectItem = () => {
  if (Object.keys(statefulEntityItem?.value ?? {}).length) {
    gaEventRef.value?.sendEvent({ clearEcommerce: false })
  }
}
</script>

<template>
  <div class="w-full" @click.native.capture="sendGA4EventSelectItem">
    <GAEvent
      :event="event"
      :eventKey="eventKey"
      :payload="payload"
      :lazy="true"
      ref="gaEventRef"
    >
      <slot />
    </GAEvent>
  </div>
</template>
