<script setup lang="ts">
import { SearchProps } from "components/ProductTile/Overall/Search.props"
import { OverbookingProps } from "./Overbooking.props"
import { ResultsProps } from "components/ProductTile/Overall/Results.props"
import { ProductAlgolia } from "utils/mapProducts"
const props = defineProps<OverbookingProps>()

const { t } = useI18n()

const emit =
  defineEmits<{ (e: "onCloseModal"): void; (e: "onDiscardProduct"): void }>()

const handleDiscard = () => {
  emit("onCloseModal")
  useTimeoutFn(() => {
    if (useRoute().name !== "carrello") {
      removeFromSoldOutCompareData(props.overbookedProduct.id)
    }
    removeFromLimitedCompareData(props.overbookedProduct.id)
  }, 500)
}

const {
  removeFromSoldOutCompareData,
  removeFromLimitedCompareData,
  shippingInfos
} = useCart()

const { data: result } = useLazyFetch(
  `/api/algolia/getRecommendations/recommend-3-p/${props.overbookedProduct.productCode}`,
  {
    server: false
  }
)

const similarProducts = computed(() => {
  const products: ProductAlgolia[] = _flattenDeep(
    result?.value?.results?.map((item) => item.hits) ?? []
  )
  return mapProductsAlgolia(products).map((i: ResultsProps): SearchProps => {
    const { slug, rating, reviews, brand, mainCategoryName, ...rest } = i
    return rest
  })
})

const shippingMessage = computed(() => {
  // FIXME: Cercare i punti dove vengono usati {freeShippingAmount} e {shippingCost}.
  // Mettere i due valori in una variabile di ambiente o simili (diversi metodi di pagamento potrebbero avere diverse logiche)
  // e diversi ambienti potrebbero avere logiche a loro volta differenti
  const freeShipping = shippingInfos.shippingInfos.value?.freeShipping
  const formatted_free_over_amount =
    shippingInfos.shippingInfos.value?.shippingMethod
      .formatted_free_over_amount || "N.A."
  const formatted_price_amount =
    shippingInfos.shippingInfos.value?.shippingMethod.formatted_price_amount ||
    "N.A."
  switch (true) {
    case isNumber(freeShipping):
      return t("dialogsAndModals.overbooking.helper.noFreeShippingNotice", {
        freeShippingAmount: formatted_free_over_amount,
        shippingCost: formatted_price_amount
      })

    case freeShipping === true:
      return t("dialogsAndModals.overbooking.helper.isFreeShippingNotice", {
        freeShippingAmount: formatted_free_over_amount
      })

    default:
      return t("dialogsAndModals.overbooking.helper.genericShippingNotice", {
        freeShippingAmount: formatted_free_over_amount
      })
  }
})
</script>

<template>
  <div
    class="
      overbooking-modal-wrapper
      flex
      h-screen
      min-w-[100vw]
      flex-col
      overflow-auto
      shadow-01
      md:h-auto
      md:min-w-[900px]
      md:rounded-lg
    "
  >
    <div
      class="
        overbooking-modal-header
        flex
        items-center
        justify-between
        gap-2
        bg-slate-800
        px-4
        py-3
        text-white
        md:px-6
      "
    >
      <p class="pig-bold md:dophin-bold">
        {{ $t("dialogsAndModals.overbooking.title") }}
      </p>
      <UtilsIcon
        name="Close.svg"
        color="white"
        @click="handleDiscard"
        class="h-6 w-6 cursor-pointer"
      />
    </div>

    <div class="modal-content flex-grow bg-white px-4 py-6 md:px-6">
      <p class="dolphin-bold hyppo-bold mb-4 text-black-main">
        {{ $t("dialogsAndModals.overbooking.description") }}
      </p>
      <ProductTileOverallOverbooking v-bind="overbookedProduct" class="mb-4" />

      <p class="dolphin-bold mb-4 text-green-main md:hidden">
        {{ $t("dialogsAndModals.overbooking.title") }}
      </p>
      <div class="grid grid-cols-2 gap-y-4 md:gap-x-6 md:gap-y-8">
        <ProductTileOverallSearch
          v-for="product in similarProducts"
          v-bind="product"
          :add-to-cart-button-text="
            $t('dialogsAndModals.overbooking.substituteCta')
          "
          :show-price-per-unit="false"
          @notification="handleDiscard"
          class="col-span-full md:col-span-1"
        />
        <div
          class="
            overbooking-discard
            col-span-full
            transform-gpu
            rounded-lg
            bg-white
            px-4
            py-2
            drop-shadow-01
            transition
            hover:drop-shadow-02
            md:col-span-1
            md:flex md:flex-col
          "
        >
          <p class="pig-bold mb-[7px] text-green-main md:hidden">
            {{ $t("dialogsAndModals.overbooking.helper.mobileTitle") }}
          </p>
          <p class="pig-bold mb-[7px] hidden text-green-main md:block">
            {{ $t("dialogsAndModals.overbooking.helper.desktopTitle") }}
          </p>

          <UtilsMarkdown
            class="mouse mb-[10px]"
            :content="shippingMessage || ''"
          />

          <UtilsButton
            :text="$t('dialogsAndModals.overbooking.helper.ctaText')"
            theme="outlined-green"
            @click="handleDiscard"
            class="custom-btn-mouse-medium-md-mouse mt-auto w-full"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">

:deep(.overbooking-discard .btn__container) {
  @apply beaver md:mouse md:py-[6.5px];
}

</style>
