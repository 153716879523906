import validate from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/validate.js";
import _00_45page_45global from "/opt/build/repo/middleware/00.page.global.ts";
import check_45sould_45out_45global from "/opt/build/repo/middleware/checkSouldOut.global.ts";
import restore_45header_45global from "/opt/build/repo/middleware/restoreHeader.global.ts";
import save_45return_45url_45global from "/opt/build/repo/middleware/save-return-url.global.ts";
import redirectsMiddleware from "/opt/build/repo/.nuxt/nuxt-redirects/redirectsMiddleware.ts";
import manifest_45route_45rule from "/opt/build/repo/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _00_45page_45global,
  check_45sould_45out_45global,
  restore_45header_45global,
  save_45return_45url_45global,
  redirectsMiddleware,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/opt/build/repo/middleware/auth.ts"),
  "logged-in-redirect-home": () => import("/opt/build/repo/middleware/logged-in-redirect-home.ts"),
  precheckout: () => import("/opt/build/repo/middleware/precheckout.ts"),
  "user-check-redirect-login-signup-page": () => import("/opt/build/repo/middleware/user-check-redirect-login-signup-page.ts")
}