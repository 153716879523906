<script setup lang="ts">
import type { ButtonProps } from "./Button.props"
import { useSlots } from "vue"

const props = withDefaults(defineProps<ButtonProps>(), {
  type: "button",
  theme: "contained-yellow",
  order: "first",
  isSelectable: false,
  outsideSelected: false,
  isDisabled: false,
  textUnderline: false,
  fluid: false,
  isFab: false,
  isLoading: false,
  stop: false,
  prevent: false,
  useTagHeading: false,
  truncate: false,
  isInnerFullHeight: false
})

const emit =
  defineEmits<{
    (e: "click"): void
  }>()

let slots = useSlots()
let hasIcon = !!slots.icon

//if there's not text, the button is just an icon
const isIconButton = computed(() => !props.text)
</script>

<template>
  <AppLink
    tabindex="0"
    :type="type"
    :class="[
      { 'w-full': fluid },
      { 'pointer-events-none [&_*]:pointer-events-none': isLoading }
    ]"
    :to="path"
    :is-disabled="isDisabled || isLoading"
    :force-self="forceSelf"
    :stop="stop"
    :prevent="prevent"
    @click="emit('click')"
  >
    <span
      class="btn__container w-full"
      :class="[
        `btn-${theme}`,
        { fab: isFab },
        { disabled: isDisabled },
        { 'icon-paddings': isIconButton },
        { 'h-full': isInnerFullHeight }
      ]"
    >
      <span
        v-if="hasIcon"
        class="icon__container"
        :class="[`icon-${order}`, { '!m-0': isIconButton }]"
      >
        <slot name="icon" />
      </span>

      <span
        v-if="!useTagHeading && text"
        :class="[
          'btn__text',
          'btn-text-class',
          { 'w-full truncate': truncate }
        ]"
        v-html="text"
      />
      <h3
        v-if="useTagHeading && text"
        class="btn__text btn-text-class"
        v-html="text"
      />
    </span>
  </AppLink>
</template>
