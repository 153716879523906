import { useState, useRuntimeConfig } from "nuxt/app";

export type GA4Events = {
    custom_event: {
        action: "view_item_list";
        event: "custom_event";
        item_list_name: string;
        filters: {
            type: string;
            value: string;
        }[];
        sort_value: string;
        ecommerce: {
            items: {
                item_id: string;
                item_name: string;
                index?: number | undefined;
                item_list_name?: string | undefined;
                item_brand?: string | undefined;
                item_category?: string | undefined;
                item_category2?: string | undefined;
                item_category3?: string | undefined;
                item_category4?: string | undefined;
                item_category5?: string | undefined;
                price?: string | undefined;
                quantity?: number | undefined;
                recurring: "si" | "no";
                discount?: string | undefined;
                bundle_name?: string | undefined;
                available: "si" | "no";
                currency?: "EUR";
            }[];
        };
    } | {
        action: "select_item";
        event: "custom_event";
        ecommerce: {
            items: {
                item_id: string;
                item_name: string;
                index?: number | undefined;
                item_list_name?: string | undefined;
                item_brand?: string | undefined;
                item_category?: string | undefined;
                item_category2?: string | undefined;
                item_category3?: string | undefined;
                item_category4?: string | undefined;
                item_category5?: string | undefined;
                price?: string | undefined;
                quantity?: number | undefined;
                recurring: "si" | "no";
                discount?: string | undefined;
                bundle_name?: string | undefined;
                available: "si" | "no";
                currency?: "EUR";
            }[];
        };
        item_list_name: string;
    } | {
        event: "custom_event";
        action: "view_item";
        ecommerce: {
            cross_selling: boolean;
            bundle: boolean;
            recurring_page: boolean;
            value: string;
            items: {
                item_id: string;
                item_name: string;
                index?: number | undefined;
                item_list_name?: string | undefined;
                item_brand?: string | undefined;
                item_category?: string | undefined;
                item_category2?: string | undefined;
                item_category3?: string | undefined;
                item_category4?: string | undefined;
                item_category5?: string | undefined;
                price?: string | undefined;
                quantity?: number | undefined;
                recurring: "si" | "no";
                discount?: string | undefined;
                bundle_name?: string | undefined;
                available: "si" | "no";
                currency?: "EUR";
            }[];
        };
    } | {
        event: "custom_event";
        action: "add_to_wishlist";
        ecommerce: {
            value: string;
            items: {
                item_id: string;
                item_name: string;
                index?: number | undefined;
                item_list_name?: string | undefined;
                item_brand?: string | undefined;
                item_category?: string | undefined;
                item_category2?: string | undefined;
                item_category3?: string | undefined;
                item_category4?: string | undefined;
                item_category5?: string | undefined;
                price?: string | undefined;
                quantity?: number | undefined;
                recurring: "si" | "no";
                discount?: string | undefined;
                bundle_name?: string | undefined;
                available: "si" | "no";
                currency?: "EUR";
            }[];
        };
    } | {
        event: "custom_event";
        action: "add_to_cart";
        ecommerce: {
            value: string;
            position: string;
            items: {
                item_id: string;
                item_name: string;
                index?: number | undefined;
                item_list_name?: string | undefined;
                item_brand?: string | undefined;
                item_category?: string | undefined;
                item_category2?: string | undefined;
                item_category3?: string | undefined;
                item_category4?: string | undefined;
                item_category5?: string | undefined;
                price?: string | undefined;
                quantity?: number | undefined;
                recurring: "si" | "no";
                discount?: string | undefined;
                bundle_name?: string | undefined;
                available: "si" | "no";
                currency?: "EUR";
            }[];
        };
        recurring_add: boolean;
    } | {
        event: "custom_event";
        action: "remove_from_cart";
        ecommerce: {
            value: string;
            items: {
                item_id: string;
                item_name: string;
                index?: number | undefined;
                item_list_name?: string | undefined;
                item_brand?: string | undefined;
                item_category?: string | undefined;
                item_category2?: string | undefined;
                item_category3?: string | undefined;
                item_category4?: string | undefined;
                item_category5?: string | undefined;
                price?: string | undefined;
                quantity?: number | undefined;
                recurring: "si" | "no";
                discount?: string | undefined;
                bundle_name?: string | undefined;
                available: "si" | "no";
                currency?: "EUR";
            }[];
        };
    } | {
        event: "custom_event";
        action: "save_later";
        ecommerce: {
            value: string;
            items: {
                item_id: string;
                item_name: string;
                index?: number | undefined;
                item_list_name?: string | undefined;
                item_brand?: string | undefined;
                item_category?: string | undefined;
                item_category2?: string | undefined;
                item_category3?: string | undefined;
                item_category4?: string | undefined;
                item_category5?: string | undefined;
                price?: string | undefined;
                quantity?: number | undefined;
                recurring: "si" | "no";
                discount?: string | undefined;
                bundle_name?: string | undefined;
                available: "si" | "no";
                currency?: "EUR";
            }[];
        };
    } | {
        event: "custom_event";
        action: "view_cart_empty";
    } | {
        event: "custom_event";
        action: "view_cart";
        ecommerce: {
            value: string;
            items: {
                item_id: string;
                item_name: string;
                index?: number | undefined;
                item_list_name?: string | undefined;
                item_brand?: string | undefined;
                item_category?: string | undefined;
                item_category2?: string | undefined;
                item_category3?: string | undefined;
                item_category4?: string | undefined;
                item_category5?: string | undefined;
                price?: string | undefined;
                quantity?: number | undefined;
                recurring: "si" | "no";
                discount?: string | undefined;
                bundle_name?: string | undefined;
                available: "si" | "no";
                currency?: "EUR";
            }[];
        };
    } | {
        event: "custom_event";
        action: "begin_checkout";
        ecommerce: {
            value: string;
            items: {
                item_id: string;
                item_name: string;
                index?: number | undefined;
                item_list_name?: string | undefined;
                item_brand?: string | undefined;
                item_category?: string | undefined;
                item_category2?: string | undefined;
                item_category3?: string | undefined;
                item_category4?: string | undefined;
                item_category5?: string | undefined;
                price?: string | undefined;
                quantity?: number | undefined;
                recurring: "si" | "no";
                discount?: string | undefined;
                bundle_name?: string | undefined;
                available: "si" | "no";
                currency?: "EUR";
            }[];
        };
        index_step: "1";
    } | {
        event: "custom_event";
        action: "add_shipping_info";
        ecommerce: {
            value: string;
            items: {
                item_id: string;
                item_name: string;
                index?: number | undefined;
                item_list_name?: string | undefined;
                item_brand?: string | undefined;
                item_category?: string | undefined;
                item_category2?: string | undefined;
                item_category3?: string | undefined;
                item_category4?: string | undefined;
                item_category5?: string | undefined;
                price?: string | undefined;
                quantity?: number | undefined;
                recurring: "si" | "no";
                discount?: string | undefined;
                bundle_name?: string | undefined;
                available: "si" | "no";
                currency?: "EUR";
            }[];
        };
        index_step: "2";
    } | {
        event: "custom_event";
        action: "add_payment_info";
        ecommerce: {
            value: string;
            items: {
                item_id: string;
                item_name: string;
                index?: number | undefined;
                item_list_name?: string | undefined;
                item_brand?: string | undefined;
                item_category?: string | undefined;
                item_category2?: string | undefined;
                item_category3?: string | undefined;
                item_category4?: string | undefined;
                item_category5?: string | undefined;
                price?: string | undefined;
                quantity?: number | undefined;
                recurring: "si" | "no";
                discount?: string | undefined;
                bundle_name?: string | undefined;
                available: "si" | "no";
                currency?: "EUR";
            }[];
        };
        index_step: "3";
    } | {
        event: "custom_event";
        action: "add_billing_info";
        ecommerce: {
            value: string;
            items: {
                item_id: string;
                item_name: string;
                index?: number | undefined;
                item_list_name?: string | undefined;
                item_brand?: string | undefined;
                item_category?: string | undefined;
                item_category2?: string | undefined;
                item_category3?: string | undefined;
                item_category4?: string | undefined;
                item_category5?: string | undefined;
                price?: string | undefined;
                quantity?: number | undefined;
                recurring: "si" | "no";
                discount?: string | undefined;
                bundle_name?: string | undefined;
                available: "si" | "no";
                currency?: "EUR";
            }[];
        };
        index_step: "3";
    } | {
        event: "custom_event";
        action: "purchase";
        ecommerce: {
            transaction_id: string;
            tax: string;
            value: string;
            items: {
                item_id: string;
                item_name: string;
                index?: number | undefined;
                item_list_name?: string | undefined;
                item_brand?: string | undefined;
                item_category?: string | undefined;
                item_category2?: string | undefined;
                item_category3?: string | undefined;
                item_category4?: string | undefined;
                item_category5?: string | undefined;
                price?: string | undefined;
                quantity?: number | undefined;
                recurring: "si" | "no";
                discount?: string | undefined;
                bundle_name?: string | undefined;
                available: "si" | "no";
                currency?: "EUR";
            }[];
            shipping_tier: string;
            shipping: string;
            coupon: string;
            payment_type: string;
            guest_customer_email: string;
        };
    } | {
        event: "custom_event";
        action: "search";
        search_term: string;
    } | {
        event: "custom_event";
        action: "view_search_results";
        search_results_number: number;
        search_term: string;
    } | {
        event: "custom_event";
        action: "generate_lead";
        form_id: string;
    } | {
        event: "custom_event";
        action: "sign_up";
        sign_up_type: "easy" | "star";
        method: ("default" | "google") | "facebook";
    } | {
        event: "custom_event";
        action: "login";
        method: ("default" | "google") | "facebook";
    } | {
        event: "custom_event";
        action: "customer_help";
        method: (("faq" | "telefono") | "form") | "chat";
        contact_type: ("faq" | "servizio clienti") | "farmacista";
        faq: string;
    } | {
        event: "custom_event";
        action: "view_promotions";
        ecommerce: {
            promotions: {
                promotion_id: string;
                promotion_name: string;
                location_id: string;
            }[];
        };
    } | {
        event: "custom_event";
        action: "select_promotion";
        ecommerce: {
            promotions: {
                promotion_id: string;
                promotion_name: string;
                location_id: string;
            }[];
        };
    } | {
        event: "custom_event";
        action: "product_availability_warning";
        items: {
            item_id: string;
            item_name: string;
            index?: number | undefined;
            item_list_name?: string | undefined;
            item_brand?: string | undefined;
            item_category?: string | undefined;
            item_category2?: string | undefined;
            item_category3?: string | undefined;
            item_category4?: string | undefined;
            item_category5?: string | undefined;
            price?: string | undefined;
            quantity?: number | undefined;
            recurring: "si" | "no";
            discount?: string | undefined;
            bundle_name?: string | undefined;
            available: "si" | "no";
            currency?: "EUR";
        }[];
    } | {
        event: "custom_event";
        action: "availability_lead";
        items: {
            item_id: string;
            item_name: string;
            index?: number | undefined;
            item_list_name?: string | undefined;
            item_brand?: string | undefined;
            item_category?: string | undefined;
            item_category2?: string | undefined;
            item_category3?: string | undefined;
            item_category4?: string | undefined;
            item_category5?: string | undefined;
            price?: string | undefined;
            quantity?: number | undefined;
            recurring: "si" | "no";
            discount?: string | undefined;
            bundle_name?: string | undefined;
            available: "si" | "no";
            currency?: "EUR";
        }[];
    } | {
        event: "custom_event";
        action: "product_request";
    } | {
        event: "custom_event";
        action: "navigation_click";
        navigation_type: "side menu";
        label: string;
    } | {
        event: "custom_event";
        action: "select_content";
        content_type: "filter";
        filter_type: "filter" | "sort";
        filter_label: string;
    } | {
        event: "custom_event";
        action: "error_feedback";
        error_type: "404" | "validation";
        form_id: string;
        feedback_title: string;
    } | {
        event: "custom_event";
        action: "coupon_use";
        coupon_code: string;
        coupon_value: string;
        coupon_outocome: "ok" | "ko";
    } | {
        event: "custom_event";
        action: "refund_request";
        ecommerce: {
            transaction_id: string;
            currency?: string | undefined;
            tax: string;
            value: string;
            items: {
                item_id: string;
                item_name: string;
                index?: number | undefined;
                item_list_name?: string | undefined;
                item_brand?: string | undefined;
                item_category?: string | undefined;
                item_category2?: string | undefined;
                item_category3?: string | undefined;
                item_category4?: string | undefined;
                item_category5?: string | undefined;
                price?: string | undefined;
                quantity?: number | undefined;
                recurring: "si" | "no";
                discount?: string | undefined;
                bundle_name?: string | undefined;
                available: "si" | "no";
                currency?: "EUR";
            }[];
            shipping_type?: string | undefined;
            shipping_cost?: string | undefined;
            payment_type?: string | undefined;
        };
    } | {
        event: "custom_event";
        action: "order_tracking";
        order_status: string;
        ecommerce: {
            transaction_id: string;
            currency?: string | undefined;
            tax: string;
            value: string;
            items: {
                item_id: string;
                item_name: string;
                index?: number | undefined;
                item_list_name?: string | undefined;
                item_brand?: string | undefined;
                item_category?: string | undefined;
                item_category2?: string | undefined;
                item_category3?: string | undefined;
                item_category4?: string | undefined;
                item_category5?: string | undefined;
                price?: string | undefined;
                quantity?: number | undefined;
                recurring: "si" | "no";
                discount?: string | undefined;
                bundle_name?: string | undefined;
                available: "si" | "no";
                currency?: "EUR";
            }[];
            shipping_type?: string | undefined;
            shipping_cost?: string | undefined;
            payment_type?: string | undefined;
        };
    };
    page_view: {
        action: "page_view";
        event: "page_view";
        page_type: string;
        customer_id: string;
        customer_email: string;
        customer_age: string;
        customer_type: string;
        login_status: string;
        store_country: string;
        site_language: string;
        currency: string;
        responsive_layout: string;
    };
};

export function useGAEvent<
  TKey extends string,
  TEvent extends keyof GA4Events
>(key: TKey, eventName: TEvent) {
  const event = useState<Partial<GA4Events[TEvent]>>(
    key + eventName,
    () => ({})
  );

  type SendEventOptions = {
    clearEcommerce: boolean
  };

  const sendEvent = (options: SendEventOptions = { clearEcommerce: true }) => {
    if (process.client) {
      const layer = useRuntimeConfig().public?.ga4?.layer as string;
      const debug = useRuntimeConfig().public?.ga4?.debug as string;

      // @ts-ignore
      if (debug === 'true') {
        console.log("Nuxt GA4 - debug event", {
          ...event.value,
        });
      } else {
        // @ts-ignore
        if (!window[layer]) {
          // @ts-ignore
          window[layer] = [];
        }
        // @ts-ignore
        if(Object.keys(event.value).includes('ecommerce') && options.clearEcommerce){
          // @ts-ignore
          window[layer]?.push({ ecommerce: null });
        }
        // @ts-ignore
        window[layer]?.push({ ...event.value });
      }
    }
  };

  return { event, sendEvent };
}

