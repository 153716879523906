import type { GA4Events } from "nuxt-ga4"
import type { Order } from "@commercelayer/sdk"
import { SortingModalItemProps } from "~/components/Filters/Utils/SortingModalItem.props"

type AddToCartPayload = {
  currentPrice?: string
  position?: string
} | null

type RemoveFromCartPayload = {
  currentPrice?: string
} | null

type SaveLaterPayload = {
  currentPrice?: string
} | null

type RemoveQuantityFromCart = {
  quantity?: number
} | null

type AddQuantityFromCart = {
  quantity?: number
} | null

type SelectContentFilter = SortingModalItemProps

export type MethodsType = {
  customerServiceCall: string
  customerServiceChat: string
  customerServiceFaq: string
  customerServiceMail: string
}

export const useGA4SiteEvents = () => {
  /**
   * @description Send a add_to_cart event to gtm
   *
   * @param {GA4Events["custom_event"]} item
   * @param {AddToCartPayload} payload
   */
  const sendEventAddToCart = (
    ga4Item: GA4Events["custom_event"],
    payload: AddToCartPayload = null
  ) => {
    const { event, sendEvent } = useGAEvent(
      `event_add_to_cart_${ga4Item.item_id ?? ""}_`,
      "custom_event"
    )
    event.value = {
      event: "custom_event",
      action: "add_to_cart",
      ecommerce: {
        currency: "EUR",
        position: payload?.position ?? "",
        value: payload?.currentPrice ?? "",
        items: [ga4Item]
      },
      recurring_add: false
    }

    sendEvent({ clearEcommerce: false })
  }

  /**
   * @description Updates the cart list based on the line items from the cart.
   *
   * @return {void} This function does not return a value.
   */
  const updateCartList = () => {
    const cartList = useGAEntity("cartList")
    const { lineItems } = useCart()
    if (!lineItems.value?.length || process.server) {
      return
    }

    cartList.value =
      lineItems.value
        ?.map((item) => item?.metadata?.ga4Item ?? null)
        ?.filter((i) => !!i) ?? []
  }

  /**
   * @description Send a remove_from_cart event to gtm
   *
   * @param {string} productCode
   * @param {RemoveFromCartPayload} payload
   */
  const sendEventRemoveFromCart = (
    ga4Item: GA4Events["custom_event"],
    payload: RemoveFromCartPayload = null
  ) => {
    const { event, sendEvent } = useGAEvent(
      `track_remove_from_cart_${ga4Item.item_id ?? ""}_`,
      "custom_event"
    )

    event.value = {
      event: "custom_event",
      action: "remove_from_cart",
      ecommerce: {
        value: payload?.currentPrice ?? "",
        items: [ga4Item]
      }
    }

    sendEvent({ clearEcommerce: false })
  }

  /**
   * @description Send a save_later event to gtm
   *
   * @param {GA4Events["custom_event"]} ga4Item
   * @param {SaveLaterPayload}payload
   */
  const sendEventSaveLater = (
    ga4Item: GA4Events["custom_event"],
    payload: SaveLaterPayload = null
  ) => {
    const { event, sendEvent } = useGAEvent(
      `track_save_later_${ga4Item.item_id ?? ""}`,
      "custom_event"
    )

    event.value = {
      event: "custom_event",
      action: "save_later",
      ecommerce: {
        value: payload?.currentPrice ?? "",
        items: [ga4Item]
      }
    }
    sendEvent({ clearEcommerce: false })
  }

  const sendEventSelectContent = (
    filter: SelectContentFilter,
    filterType: "sort" | "filter" | undefined
  ) => {
    const { event, sendEvent } = useGAEvent(
      `track_select_content_sorting_`,
      "custom_event"
    )

    event.value = {
      event: "custom_event",
      action: "select_content",
      content_type: "filter",
      filter_type: filterType,
      filter_label: filter.label
    }
    sendEvent({ clearEcommerce: false })
  }

  const sendEventCustomerHelp = (
    method: MethodsType[keyof MethodsType],
    contact_type: "farmacista" | "servizio clienti"
  ) => {
    const { event, sendEvent } = useGAEvent(
      `track_customer_help_event`,
      "custom_event"
    )

    event.value = {
      event: "custom_event",
      action: "customer_help",
      method,
      contact_type
    }

    sendEvent({ clearEcommerce: false })
  }

  /**
   * @description Send add_to_cart event every time update quantity from cart
   * @param {string} productCode
   * @param {AddQuantityFromCart} payload
   */
  const addQuantityFromCart = (
    productCode: string,
    payload: AddQuantityFromCart
  ) => {
    const cartList = useGAEntity("cartList")

    const quantity = payload?.quantity ?? 1
    const ga4Item = cartList.value?.find(
      (item) => item?.item_id === productCode
    )

    sendEventAddToCart(
      { ...ga4Item, quantity },
      {
        currentPrice: ga4Item?.price ?? ""
      }
    )
  }

  /**
   * @description Send remove_to_cart event every time update quantity from cart
   * @param {string} productCode
   * @param {RemoveQuantityFromCart} payload
   */
  const removeQuantityFromCart = (
    productCode: string,
    payload: RemoveQuantityFromCart
  ) => {
    const cartList = useGAEntity("cartList")

    const quantity = payload?.quantity ?? 1
    const ga4Item = cartList.value?.find(
      (item) => item?.item_id === productCode
    )

    sendEventRemoveFromCart(
      { ...ga4Item, quantity },
      {
        currentPrice: ga4Item?.price ?? ""
      }
    )
  }
  /**
   * @description Send a save_later event from cart
   *
   * @param {string} productCode
   */
  const saveLaterFromCart = (productCode: string) => {
    const cartList = useGAEntity("cartList")

    const ga4Item = cartList.value?.find(
      (item) => item?.item_id === productCode
    )

    sendEventSaveLater(
      { ...ga4Item, quantity: 1 },
      {
        currentPrice: ga4Item?.price ?? ""
      }
    )
  }

  const sortFilter = (filter: SelectContentFilter) => {
    const pageList = useGAEntity("pageList")
    if (pageList.value) {
      pageList.value.sort = filter.label ?? ""
    }
    sendEventSelectContent(filter, "sort")
  }

  const customerHelp = (iconType: keyof MethodsType) => {
    const methods: MethodsType = {
      customerServiceCall: "telefono",
      customerServiceChat: "mail",
      customerServiceFaq: "form",
      customerServiceMail: "form"
    }

    if (!methods[iconType]) {
      return
    }

    const method = methods[iconType]
    const contentType =
      iconType === "customerServiceMail" ? "farmacista" : "servizio clienti"

    sendEventCustomerHelp(method, contentType)
  }

  const findGa4ItemFromLineItems = (order: Order) => {
    return order?.line_items
      ?.map((item) =>
        item?.metadata?.ga4Item
          ? { ...item?.metadata?.ga4Item, quantity: item.quantity }
          : null
      )
      ?.filter((i) => !!i)
  }

  const sendEventCouponUse = (discount: string) => {
    const { event, sendEvent } = useGAEvent(
      `track_coupon_use_${discount ?? ""}`,
      "custom_event"
    )
    event.value = {
      event: "custom_event",
      action: "coupon_use",
      coupon_code: discount,
      coupon_value: "",
      coupon_outocome: "ko"
    }
    sendEvent({ clearEcommerce: false })
  }

  const sendEventBeginCheckout = (order: Order) => {
    const { event, sendEvent } = useGAEvent(
      `track_begin_checkout_${order?.id}`,
      "custom_event"
    )
    event.value = {
      event: "custom_event",
      action: "begin_checkout",
      ecommerce: {
        currency: order?.currency_code ?? "",
        value: order?.total_amount_with_taxes_float?.toString() ?? "",
        items: findGa4ItemFromLineItems(order)
      },
      index_step: "1"
    }
    sendEvent({ clearEcommerce: false })
  }

  const sendEventAddShippingInfo = (order: Order) => {
    const { event, sendEvent } = useGAEvent(
      `track_add_shipping_info_${order?.id}`,
      "custom_event"
    )
    event.value = {
      event: "custom_event",
      action: "add_shipping_info",
      ecommerce: {
        currency: order?.currency_code ?? "",
        value: order?.total_amount_with_taxes_float?.toString() ?? "",
        items: findGa4ItemFromLineItems(order)
      },
      index_step: "2"
    }
    sendEvent({ clearEcommerce: false })
  }

  const sendEventAddPaymentInfo = (order: Order) => {
    const { event, sendEvent } = useGAEvent(
      `track_add_payment_info_${order?.id}`,
      "custom_event"
    )
    event.value = {
      event: "custom_event",
      action: "add_payment_info",
      ecommerce: {
        currency: order?.currency_code ?? "",
        value: order?.total_amount_with_taxes_float?.toString() ?? "",
        items: findGa4ItemFromLineItems(order)
      },
      index_step: "3"
    }
    sendEvent({ clearEcommerce: false })
  }

  const sendEventPurchase = (order: Order) => {
    const { event, sendEvent } = useGAEvent(
      `track_purchase_${order?.id}`,
      "custom_event"
    )

    const shipments = order?.shipments?.map(
      (s) => s?.shipping_method?.scheme ?? ""
    )
    const transaction_id = order.reference ?? ""
    const paymentMethodDetails =
      order?.payment_source_details?.payment_method_details?.paymentMethod
    const isGooglePayPayment = paymentMethodDetails
      ?.toLowerCase()
      ?.includes("googlepay")
    const isApplePayPayment = paymentMethodDetails
      ?.toLowerCase()
      ?.includes("apple")

    event.value = {
      event: "custom_event",
      action: "purchase",
      ecommerce: {
        transaction_id,
        tax: order.total_tax_amount_float?.toString() ?? "",
        currency: order?.currency_code ?? "",
        value: order?.total_amount_with_taxes_float?.toString() ?? "",
        items: findGa4ItemFromLineItems(order),
        shipping_tier: shipments?.join(", ") ?? "",
        shipping: order?.shipping_amount_float ?? "",
        coupon: order?.coupon_code ?? "",
        payment_type: isGooglePayPayment
          ? "googlepay"
          : isApplePayPayment
          ? "applepay"
          : order?.payment_source_details?.type ?? "postepay",
        guest_customer_email: order?.customer_email ?? ""
      }
    }
    sendEvent({ clearEcommerce: false })
  }
  /**
   * @description Send error fired from an invalid submit of form
   * @param {string} formId
   */
  const sendEventErrorForm = (formId: string) => {
    const { event, sendEvent } = useGAEvent(
      `event_error_form_${formId}`,
      "custom_event"
    )
    event.value = {
      event: "custom_event",
      action: "error_feedback",
      error_type: "validation",
      form_id: formId,
      feedback_title: `${formId} invalid submit`
    }

    sendEvent({ clearEcommerce: false })
  }

  return {
    sendEventAddToCart,
    sendEventRemoveFromCart,
    sendEventSaveLater,
    sendEventSelectContent,
    sendEventCustomerHelp,
    addQuantityFromCart,
    removeQuantityFromCart,
    saveLaterFromCart,
    sortFilter,
    customerHelp,
    sendEventCouponUse,
    sendEventBeginCheckout,
    sendEventAddShippingInfo,
    sendEventAddPaymentInfo,
    sendEventPurchase,
    sendEventErrorForm,
    updateCartList
  }
}
