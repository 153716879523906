<script setup lang="ts">
import type { GA4Entities } from "nuxt-ga4"
import type { VerticalProps } from "./ProductTile/Overall/Vertical.props"

const props =
  defineProps<{
    id: string
    item?: VerticalProps[] //TODO: rename this to "items" instead
    disable?: boolean
  }>()

const statefulEntityList:
  | [Exclude<keyof GA4Entities, "cartList" & "gaItem">]
  | undefined = inject("statefulEntityList")

const mapItems = (item: VerticalProps, i: number): GA4Entities["gaItem"] => {
  const promotion = item?.promotions?.find((p) => p.text) ?? null
  const discount = promotion
    ? Number(item?.oldPrice - item?.currentPrice).toFixed(2)
    : ""

  const newIndex = item?.position ?? i + 1 ?? 0

  return {
    item_id: item?.productCode ?? item?.skuCode ?? "",
    item_name: item?.title ?? "",
    index: newIndex,
    item_list_name: statefulEntityList?.value?.list_name ?? "",
    item_brand: item?.brand?.name ?? "",
    item_category: item?.mainCategoryName ?? "",
    price: item?.currentPrice?.toString() ?? "",
    discount: discount ?? promotion?.text ?? promotion?.theme ?? "",
    available: item?.inStock ? "si" : "no",
    quantity: 1,
    recurring: item?.recurring ? "si" : "no",
    bundle_name: item?.bundleName ? item?.bundleName : "",
    currency: "EUR"
  }
}

const payload = computed<GA4Entities["gaItem"][]>(
  () => props.item?.map((item, i) => mapItems(item, i)) ?? []
)

const statefulEntityItem = useGAEntity(
  `ga_item_${props.id}` as keyof GA4Entities
)
statefulEntityItem.value = payload?.value ?? {}

// Whenever the "item" prop changes, it will re-evalute the "statefulEntityItem" so you would just have to "catch" it with a "watch"
watch(
  () => props?.item,
  (newValue, oldValue) => {
    if (!isEqual(newValue, oldValue) && !props.disable) {
      statefulEntityItem.value = payload?.value ?? {}
    }
  }
)

if (!props?.disable) {
  provide("statefulEntityItem", statefulEntityItem)
  provide("id", props.id)
}
</script>

<template>
  <slot />
</template>
