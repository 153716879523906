import type {
  LineItem,
  Order,
  Promotion,
  FreeGiftPromotion,
  Shipment,
  Sku,
  OrderUpdate,
  QueryParamsRetrieve,
  LineItemCreate,
  GiftCard,
  Adjustment,
  OrderCreate
} from "@commercelayer/sdk"
import { addPrices } from "~/utils/orders/addPrices"
import type { NotificationAlertProps } from "components/NotificationAlert.props"

const chunk = (arr: unknown[], size: number) =>
  Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
    arr.slice(i * size, i * size + size)
  )

export const defaultQuery: QueryParamsRetrieve = {
  include: [
    "line_items",
    "line_items.item",
    "shipments",
    "shipments.delivery_lead_time",
    "available_free_skus"
  ]
}

export const skusQuery: QueryParamsRetrieve = {
  include: ["prices"]
}

export type LineItem_<Item extends LineItem["item"]> = LineItem & {
  item: Item
}

export const getFreeGiftQuantity =
  (freeGiftLineItems: LineItem[]) =>
  (skuLineItem: LineItem_<Sku>): number => {
    // if the amount is zero, all items are free
    if (!skuLineItem.unit_amount_cents) {
      return skuLineItem.quantity!
    }

    // find the number of free gifts by comparing the total free gift discount amount with the total amount
    // sum all free gift discounts amount
    const freeGiftDiscountAmountCents = Object.entries(
      skuLineItem?.discount_breakdown ?? {}
    )
      .filter(([discountLineItemId]) =>
        freeGiftLineItems.map(({ id }) => id).includes(discountLineItemId)
      )
      .map(([, { cents }]) => cents)
      .reduce((a, b) => a + b, 0)

    // if the total free gift discount is zero no items are free
    if (!freeGiftDiscountAmountCents) {
      return 0
    }

    // get the number of free gift items using the ratio between the total free gift discount amount and the total amount
    return Math.round(
      skuLineItem.quantity! *
        (Math.abs(freeGiftDiscountAmountCents) /
          skuLineItem.total_amount_cents!)
    )
  }

export const getReceiptLink = (order: Order) => {
  const receiptArray = order.metadata?.paymentReceipt.split("/")
  const document: string = receiptArray.at(-1)?.replace(".xml", "")
  const path: string = receiptArray.at(-2)
  return `/ricevuta/${path}_${document}`
}

export const downloadCurrentReceipt = async (
  order: Order,
  notification: { notificationError: NotificationAlertProps }
) => {
  const { notificationError } = notification
  const { addNotification } = useNotification()

  if (order.metadata?.paymentReceipt) {
    const receiptLink = getReceiptLink(order)
    if (receiptLink) {
      navigateTo(receiptLink)
    } else {
      addNotification(notificationError)
    }
  } else {
    addNotification(notificationError)
  }
}

/**
 * Order api.
 * @param {Sting} id order id.
 * @param {Object} { isCheckEditableEnabled, query }
 * @returns {Object} order data and methods.
 */
export default (
  key: string,
  id = null,
  { isCheckEditableEnabled = true, query = defaultQuery } = {}
) => {
  const { $cl } = useNuxtApp()
  const queue = useQueue()

  const orderId = useState<string | null>(`orderId-${key}`, () => id)

  const initOrder = (id: string | null) => {
    orderId.value = id
  }

  const soldOutCompareData = useState<LineItem_<Sku>[]>(
    `sold-out-compare-data-${key}`,
    () => []
  )

  const limitedCompareData = useState<LineItem_<Sku>[]>(
    `limited-compare-data-${key}`,
    () => []
  )

  const priceChangedCompareData = useState<LineItem_<Sku>[]>(
    `price-changed-compare-data-${key}`,
    () => []
  )

  watch(orderId, (orderId) => {
    if (!orderId) {
      order.value = null
    }
  })

  // ORDER DATA
  const order = useState<Order | null>(`order-${key}`, () => null)

  // SINTACTIC SUGAR TO ACCESS ORDER DATA
  const allLineItems = computed<LineItem[]>(() => order.value?.line_items ?? [])
  const allOrderShipments = computed<Shipment[]>(
    () => order.value?.shipments ?? []
  )

  const lineItems = computed(
    () =>
      allLineItems.value.filter(
        (lineItem) => lineItem.item_type === "skus"
      ) as LineItem_<Sku>[]
  )
  const promotions = computed(
    () =>
      allLineItems.value.filter(
        (lineItem) => lineItem.item_type === "promotions"
      ) as LineItem_<Promotion>[]
  )
  const freeGiftPromotions = computed(
    () =>
      allLineItems.value.filter(
        (lineItem) => lineItem.item_type === "free_gift_promotions"
      ) as LineItem_<FreeGiftPromotion>[]
  )
  const giftCards = computed(
    () =>
      allLineItems.value.filter(
        (lineItem) => lineItem.item_type === "gift_cards"
      ) as LineItem_<GiftCard>[]
  )
  const shipments = computed(
    () =>
      allLineItems.value.filter(
        (lineItem) => lineItem.item_type === "shipments"
      ) as LineItem_<Shipment>[]
  )
  const adjustments = computed(
    () =>
      allLineItems.value.filter(
        (lineItem) => lineItem.item_type === "adjustments"
      ) as LineItem_<Adjustment>[]
  )
  const freeGiftPromotionsAmountFloat = computed(() =>
    freeGiftPromotions.value
      .map((lineItem) => lineItem.total_amount_float)
      .reduce((a, b) => a + b, 0)
  )
  const subtotalAmountFloatWithoutFreeGifts = computed(
    () =>
      order.value &&
      order.value.subtotal_amount_float! + freeGiftPromotionsAmountFloat.value
  )
  const discountAmountFloatWithoutFreeGifts = computed(
    () =>
      order.value &&
      order.value.discount_amount_float! - freeGiftPromotionsAmountFloat.value
  )

  // LOADING UTILS
  const loading = ref(true)

  // CREATE, UPDATE, FETCH, INIT METHODS
  const createOrder = async (attributes: OrderCreate = {}) => {
    if (order.value) {
      return
    }

    loading.value = true

    order.value = await $cl.orders.create(
      {
        ...attributes,
        shipping_country_code_lock: "IT"
        // language_code: "IT",
        // return_url: process.env.BASE_URL,
      },
      query
    )

    orderId.value = order.value.id

    loading.value = false
  }

  const updateOrder = async (params: Omit<OrderUpdate, "id">) => {
    if (!order.value) {
      return
    }

    loading.value = true

    await $cl.orders.update(
      {
        id: order.value.id,
        ...params
      },
      query
    )
    await fetchOrder()

    loading.value = false
  }

  const fetchOrder = async (forceRefresh = false) => {
    if (!orderId.value) {
      return
    }

    loading.value = true

    try {
      if (forceRefresh) {
        let order_base = await $cl.orders.retrieve(orderId.value, {
          include: ["payment_source"]
        })
        if (!order_base.payment_source) {
          // Do not refresh orders if they have a payment source attached
          // i.e. if I'm coming back to the checkout from another page
          try {
            await $cl.orders.update({ id: orderId.value, _refresh: true })
          } catch (err: any) {
            console.warn("Warn - order not refreshable", err.response || err)
          }
        }
      }
      const data = await $cl.orders.retrieve(orderId.value, query)
      const orderWithPrices = await addPrices($cl, data)

      order.value = orderWithPrices

      checkEditable()
      nextTick(() =>
        queue.push(checkFreeGifts, [
          order.value?.line_items?.filter(
            (line_item) => line_item.item_type == "skus"
          )
        ])
      )
    } catch (err: any) {
      console.error("Error", err.response || err)
    }

    loading.value = false
  }

  const initCompare = async () => {
    if (!order.value) {
      return
    }

    const soldOut = lineItems.value?.filter(
      (lineItem) => !lineItem.item?.inventory?.available
    )
    const limitedInventory = lineItems.value?.filter(
      (lineItem) =>
        lineItem.item?.inventory?.available &&
        lineItem.quantity > lineItem.item.inventory.quantity
    )
    const priceChanged = lineItems.value?.filter(
      (lineItem) =>
        lineItem?.item?.inventory?.available &&
        lineItem?.item?.prices?.[0]?.amount_float! !==
          lineItem?.unit_amount_float!
    )

    for (const lineItem of soldOut) {
      await removeLineItemBySku(lineItem.sku_code!, false)
    }

    for (const lineItem of limitedInventory) {
      await updateQuantity(
        lineItem.id,
        lineItem.item.inventory!.quantity,
        false
      )
    }

    for (const lineItem of priceChanged) {
      await updateMetadata(lineItem.id!, false)
    }

    await fetchOrder()

    soldOutCompareData.value = soldOut
    limitedCompareData.value = limitedInventory
    priceChangedCompareData.value = priceChanged

    const hasRemovedLineItems = !!soldOut?.length || !!limitedInventory?.length
    return hasRemovedLineItems
  }

  const removeFromSoldOutCompareData = (id: string) => {
    soldOutCompareData.value = soldOutCompareData.value.filter(
      (item) => item.id !== id
    )
  }

  const removeFromLimitedCompareData = (id: string) => {
    limitedCompareData.value = limitedCompareData.value.filter(
      (item) => item.id !== id
    )
  }

  // CHECK METHODS
  // Discard not editable orders
  const checkEditable = () => {
    if (isCheckEditableEnabled && order.value && !order.value.editable) {
      orderId.value = null
      order.value = null
    }
  }

  watch(order, checkEditable)

  const addLineItem = async (
    attributes: Omit<LineItemCreate, "quantity" | "order">,
    quantity = 1,
    refresh = true
  ) => {
    if (!order.value) {
      return
    }

    if (quantity <= 0) return
    const lineItem = await $cl.line_items.create({
      ...attributes,
      quantity,
      item_type: "sku",
      _update_quantity: true,
      order: { id: order.value!.id, type: "orders" }
    })

    if (refresh) await fetchOrder()

    return lineItems.value.find((item) => item.id === lineItem.id)
  }

  const removeLineItem = async (id: string, refresh = true) => {
    const item = lineItems.value.find((item) => item.id === id)
    if (!item) return
    await $cl.line_items.delete(id)
    if (refresh) await fetchOrder()
    return item
  }

  const removeLineItemBySku = async (skuCode: string, refresh = true) => {
    const id = lineItems.value.find((item) => item.sku_code === skuCode)?.id
    if (id === undefined) return
    return removeLineItem(id, refresh)
  }

  const updateMetadata = async (id: string, refresh = true) => {
    const oldLineItem = lineItems.value.find((lineItem) => lineItem.id === id)
    if (!oldLineItem) return
    let partialLineItem
    let updatedLineItem
    try {
      partialLineItem = await $cl.line_items.update({
        id,
        quantity: oldLineItem.quantity + 1,
        metadata: {
          ...oldLineItem.metadata,
          oldPrice: oldLineItem?.item?.prices?.[0]?.compare_at_amount_float!,
          price: oldLineItem?.unit_amount_float!
        }
      })
      updatedLineItem = await $cl.line_items.update({
        id,
        quantity: partialLineItem.quantity - 1
      })
    } catch (err) {
      console.log(err)
    }
    if (refresh) await fetchOrder()

    return updatedLineItem
  }

  const updateQuantity = async (
    id: string,
    quantity: number,
    refresh = true
  ) => {
    if (quantity === 0) {
      return removeLineItem(id, refresh)
    }

    let item = lineItems.value.find((item) => item.id === id)
    if (!item) return
    item = await $cl.line_items.update({
      id,
      quantity
    })
    if (refresh) await fetchOrder()

    return item
  }

  const isFreeSkuNotAvailableAndOutOfCatalogue =
    (availableFreeSkus?: Sku[]) => (lineItem: LineItem_<Sku>) => {
      const isFreeSkuAvailable = availableFreeSkus
        ?.map((sku) => sku.code)
        ?.includes(lineItem.sku_code!)
      const isOutOfCatalogue = lineItem?.metadata?.productInCatalogue === false
      return !isFreeSkuAvailable && isOutOfCatalogue
    }

  let checkingFreeGift = false
  const checkFreeGifts = async (updatedLineItems: LineItem_<Sku>[]) => {
    if (checkingFreeGift) return
    checkingFreeGift = true
    const _lineItems = updatedLineItems.slice()
    const _availableFreeSkus = order.value?.available_free_skus?.slice()
    const allLineItemsOutOfCatalogue = _lineItems.every(
      (data) => data?.metadata?.productInCatalogue === false
    )

    const freeGiftsToRemove = allLineItemsOutOfCatalogue
      ? _lineItems
      : _lineItems.filter(
          isFreeSkuNotAvailableAndOutOfCatalogue(_availableFreeSkus)
        )

    for (const freeGift of freeGiftsToRemove) {
      await removeLineItem(freeGift.id, false)
    }

    if (freeGiftsToRemove.length) await fetchOrder()

    if (_lineItems.length && _availableFreeSkus?.length) {
      const notInCartAvailableFreeSkus = _availableFreeSkus.filter(
        (sku) =>
          !_lineItems.map((item) => item.sku_code).includes(sku.code) &&
          sku?.inventory?.available
      )

      for (const sku of notInCartAvailableFreeSkus) {
        let product = null

        try {
          if (sku.code) {
            product = await $fetch(`/api/account/getProductInfo/${sku.code}`)
          }
        } catch (error) {}

        await addLineItem(
          {
            sku_code: sku.code,
            metadata: {
              productInCatalogue: !!product,
              product_image: product?.product_image,
              slug: product?.slug ? `p-${product.slug}` : undefined
            }
          },
          1,
          false
        )
      }

      if (notInCartAvailableFreeSkus.length) await fetchOrder()
    }
    checkingFreeGift = false
  }

  const updateQuantityBySku = async (skuCode: string, quantity: number) => {
    const id = lineItems.value.find((item) => item.sku_code === skuCode)?.id
    if (id === undefined) return
    return updateQuantity(id, quantity)
  }

  const increaseQuantity = async (id: string, amount = 1) => {
    const item = lineItems.value.find((item) => item.id === id)
    if (!item) return
    return updateQuantity(id, Math.max(0, item.quantity! + amount))
  }

  const decreaseQuantity = async (id: string, amount = 1) => {
    const item = lineItems.value.find((item) => item.id === id)
    if (!item) return
    return updateQuantity(id, Math.max(0, item.quantity! - amount))
  }

  const isInOrder = (sku: string) => {
    return lineItems.value.some((item) => item.sku_code === sku)
  }

  return {
    orderId,
    initOrder,
    // ORDER DATA
    order,
    soldOutCompareData,
    limitedCompareData,
    priceChangedCompareData,
    removeFromSoldOutCompareData,
    removeFromLimitedCompareData,
    // SINTACTIC SUGAR TO ACCESS ORDER DATA
    allOrderShipments,
    lineItems,
    freeGiftPromotions,
    promotions,
    giftCards,
    shipments,
    adjustments,
    subtotalAmountFloatWithoutFreeGifts,
    discountAmountFloatWithoutFreeGifts,
    freeGiftPromotionsAmountFloat,
    // LOADING UTILS
    loading,
    // CREATE, UPDATE, FETCH METHODS
    createOrder,
    updateOrder,
    fetchOrder,
    // CHECK METHODS
    checkEditable,
    // LINE ITEMS
    addLineItem,
    removeLineItem,
    removeLineItemBySku,
    // QUANTITY
    updateQuantity,
    updateQuantityBySku,
    increaseQuantity,
    decreaseQuantity,
    isInOrder,
    //
    initCompare
  }
}
