<script lang="ts" setup>
import { VerticalProps } from "~/components/ProductTile/Overall/Vertical.props"
import type { BundleProductCardProps } from "./BundleProductCard.props"

const props = defineProps<BundleProductCardProps>()

const emit =
  defineEmits<{
    (e: "onSelect", productCode: string, isSelected: boolean): void
  }>()

const isSelected = ref(props.selected)

watch(isSelected, (newValue) => {
  emit("onSelect", props.productCode, newValue)
})
</script>

<template>
  <div
    class="
      bundle-product-card
      relative
      select-none
      overflow-hidden
      rounded-lg
      border-2 border-white
      bg-white
      p-2
      pr-7
      shadow-01
      transition-colors
      duration-300
    "
    :class="{
      'flex gap-x-1': selectable,
      '!border-green-main': selectable && isSelected
    }"
  >
    <div class="bundle-product-card__content flex items-center gap-x-3">
      <div v-if="productImage" class="bundle-product-card__image flex-shrink-0">
        <UtilsWrapperImage
          class="wrapper-image-fit-contain"
          v-bind="productImage"
          sizes="sm:165px md:165px lg:165px"
        />
      </div>
      <div v-else class="bundle-product-card__image flex-shrink-0">
        <UtilsWrapperImage
          class="wrapper-image-fit-contain"
          v-bind="fallbackImage"
        />
      </div>
      <GAItem
        :id="props.productCode ?? ''"
        :item="[
          {
            ...props as VerticalProps,
            bundleName: $t(`productPage.bundle.${type}.title`)
          }
        ]"
      >
        <GASelectItem>
          <AppLink
            v-if="!!title"
            class="mouse-medium line-clamp-4 w-auto underline"
            :to="path?.toString() ?? '#'"
          >
            {{ title }}
          </AppLink>
        </GASelectItem>
      </GAItem>
    </div>
    <ControlsCheckbox
      v-if="selectable"
      id="bundlecheckbox"
      name="bundlecheckbox"
      v-model="isSelected"
      is-big
      class="absolute right-2 top-2"
    />
  </div>
</template>

<style lang="scss">

.bundle-product-card {
  &__image {
    @apply h-[5.4375rem] w-[4.0625rem] lg:w-[4.75rem];

    .wrapper-image,
    .app-picture-wrapper,
    .app-picture-wrapper-placeholder {
      @apply h-full;
    }
  }
}

</style>
