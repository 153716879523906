import aa from "search-insights"
import { useUserStore } from "~/stores/user"
import { initAlgolia } from "~/utils/algolia"
import Cookie from "js-cookie"

export const VIEW_SAME_CATEGORY_PDP = "PDP;Same category viewed"
export const VIEW_ACTIVE_PRINCIPLE_PDP = "PDP;Active Principle viewed"

export const PRODUCT_CLICKED = "Product Clicked"
export const PRODUCT_VIEWED = "Product Viewed"
export const PRODUCT_ADDED_TO_CART = "Product Added To Cart"
export const FREQUENTLY_BOUGHT_TOGETHER = "Frequently Bought Together"

export const BRAND_CLICKED = "Brand Clicked"
export const BRAND_VIEWED = "Brand Viewed"

export const PROMOTION_CLICKED = "Promotion Clicked"
export const PROMOTION_VIEWED = "Promotion Viewed"

export const MAGAZINE_CLICKED = "Magazine Clicked"
export const MAGAZINE_VIEWED = "Magazine Viewed"

export const WIKI_ARTICLE_CLICKED = "Wiki Article Clicked"
export const WIKI_ARTICLE_VIEWED = "Wiki Article Viewed"

export const PRODUCTS_VIEWED_SEARCHBAR = "Searchbar; Products Viewed"

export const initAA = (envAlgolia: object) => { 
  const userStore = useUserStore()
  const { userId } = userStore
  const idUser = Cookie.get("_ALGOLIA") ?? userId
  const isLogged = useIsLogged()

  aa("init", {
    appId: envAlgolia.appId,
    apiKey: envAlgolia.apiKey,
    useCookie: true,
    userToken: isLogged.value ? idUser : undefined
  })
  initAlgolia()
}
